import React, { Suspense, lazy } from 'react';
import './App.css';
import 'antd/dist/reset.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Loading from '../src/components/loading';

// auth
const LandingPage = lazy(() => import('./view/LandingPage.js'));

function App() {
  return (
    <div className="app-wrapper">
      <div className="App">
        <Suspense fallback={<Loading />}>
          <Router>
            <Routes>
              <Route path="/" element={<LandingPage />} />
            </Routes>
          </Router>
        </Suspense>
      </div>
    </div>
  );
}

export default App;
